<template>
    <div class="modal-remind-before-send-real-friend-contact">
        <div class="head">{{ 'WAIT' | translate }}</div>

        <main class="main">
            <p class="m-b-16">
                보내기 전, <span class="f-bold">친구의 이름</span>과 <span class="f-bold">{{ numberOrId }}</span
                >가 정확한지 확인해주세요!
            </p>

            <p class="name-and-number">
                {{ nameAndNumer }}
            </p>
        </main>

        <footer class="footer">
            <button class="btn c-text-light" @click="$emit('close', 0)">{{ 'CANCEL' | translate }}</button>
            <button class="btn c-primary" @click="$emit('close', 1)">{{ 'SEND' | translate }}</button>
        </footer>
    </div>
</template>
<script>
export default {
    name: 'ModalNotice',
    props: {
        options: {
            type: Object,
            required: true,
        },
    },
    computed: {
        name() {
            return this.options.name
        },
        number() {
            return this.options.number
        },
        numberOrId() {
            const regex = /[a-zA-Z]/g // 알파벳 포함?

            return regex.test(this.number) ? '카카오톡 ID' : '전화번호'
        },
        nameAndNumer() {
            return `${this.name}, ${this.number}`
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-remind-before-send-real-friend-contact {
    color: $grey-09;
    width: 280px;
    padding: 0;

    .head {
        padding: 20px 16px;
        font-size: 20px;

        @include f-bold;
    }

    .main {
        padding: 0 16px 20px;
        line-height: normal;
    }

    .name-and-number {
        height: 52px;
        border-radius: 12px;
        background-color: $grey-01;
        color: black;
        font-size: 14px;

        @include f-medium;
        @include center;
    }

    .footer {
        height: 56px;
        border-top: solid 1px $grey-02;

        .btn {
            height: 100%;
            padding: 0;
            flex: 1 1 auto;
            font-size: 16px;

            @include center;
        }

        @include flex-row;
    }
}
</style>
